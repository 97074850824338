.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cardshadow{
  box-shadow: 0px 0px 9px 0px #a49d9d;
}
/* input[type=number]::-webkit-inner-spin-button {    
  opacity: 1;
} */

.checkboxparentdiv { 
  display: block; 
  position: relative; 
  padding-left: 35px; 
  margin-bottom: 15px; 
  cursor: pointer; 
  font-size: 20px; 
} 

/* Hide the default checkbox */ 
/* input[type=checkbox] { 
  visibility: hidden; 
  display: none;
}  */

/* Creating a custom checkbox 
based on demand */ 
.checkboxspan { 
  position: absolute; 
  top: 0; 
  left: 0; 
  height: 25px; 
  width: 25px; 
  background-color: white; 
  border: 2px solid grey;
  border-radius: 5px;

} 

/* Specify the background color to be 
shown when hovering over checkbox */ 
.checkboxparentdiv:hover input ~ .checkboxspan { 
  background-color: white; 
  border: 2px solid grey;
  border-radius: 5px;
} 

/* Specify the background color to be 
shown when checkbox is checked */ 
.checkboxparentdiv input:checked ~ .checkboxspan { 
  background-color: #5AB344; 
  border: none;
  border-radius: 5px;
} 

/* Checkmark to be shown in checkbox */ 
/* It is not be shown when not checked */ 
.checkboxspan:after { 
  content: ""; 
  position: absolute; 
  display: none; 
} 

/* Display checkmark when checked */ 
.checkboxparentdiv input:checked ~ .checkboxspan:after { 
  display: block; 
} 

/* Styling the checkmark using webkit */ 
/* Rotated the rectangle by 45 degree and  
showing only two border to make it look 
like a tickmark */ 
.checkboxparentdiv .checkboxspan:after { 
  left: 8px;
  bottom: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
} 

#cardquantity::-webkit-inner-spin-button,
#cardquantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#cardquantity{
  -moz-appearance: textfield;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
.auctionGridBox{
  box-shadow: 0px 0px 8px 2px #a69e9e;
}
.custom-dot-list-style{
  padding-top: 10px !important;
}
.auctionGridBox1{
  box-shadow: 0px 0px 6px 1px #a69e9e;
}