.radio-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    vertical-align: middle;
    border: 5px solid #0a0a0a;
    border-radius: 50%; /* Makes the radio button round */
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.radio-round:checked {
    background-color: #3CB043;
}
