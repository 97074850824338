@media only screen and (max-width: 600px) {
  .signup-container {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .small-devices {
    display: none;
  }

  .desktop {
    display: block;
  }

  .head {
    display: block;
  }

  .width:first-child {
    width: 50%;
  }

  .width:last-child {
    width: 50%;
  }

  .login-container {
    display: none;
  }

  .signup-container {
    display: flex;
    flex-direction: row;
    margin: 0;

    min-height: 100vh;
  }

  .signup-img {
    width: 100%;
    /* height: 400px; */
  }

  .signup-form {
    border: 1px solid #66666680;
    margin-top: 20px;
  }

  .form-data-content {
    border: 1px solid rgba(102, 102, 102, 0.5);
    margin-top: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    border-radius: 24px;
    min-height: 93vh;
    margin-bottom: 20px;
  }
}